<template>
<img src="@/assets/bg.png" alt="" class="bgImg">
<div class="wr" v-if="popap">
    <img src="@/assets/logoFull.svg" alt="" srcset="">
    <span>
      Спасибо, мы получили данные! <br><br>
Кстати, у нас обновился корпоративный сайт. Покажем Вам его через {{ int }}
    </span>
  </div>

  <div class="wr" v-else>
    <img src="@/assets/logoFull.svg" alt="" srcset="">
      <br>
    <span>
      Компания Adspector поздравляет Вас с наступающим 2024 годом! Мы хотели бы поблагодарить Вас за сотрудничество в 2023 году. Просим заполнить форму ниже, чтобы мы могли доставить Вам наш новогодний подарок
      <br><br>
P.S. Мы также запросим дату Вашего рождения, чтобы знать, когда поздравлять с этим событием 🎁
    </span>
    <label >Ваша фамилия:</label> 
    <div :class="{input:true, err:err.ferstName}">
      <div>
        <img src="@/assets/icons/u.png" alt="" srcset="">
        <input type="text" placeholder="Иванов"  v-model="data.ferstName">
      </div>
    </div>  

    <label>Ваше имя:</label> 
    <div :class="{input:true, err:err.lastName}">
      <div>
        <img src="@/assets/icons/u.png" alt="" srcset="">
        <input type="text" placeholder="Иван" v-model="data.lastName">
      </div>
    </div>  

    <label>Удобная дата получения подарка:</label> 
    <div :class="{input:true, err:err.datePres}">
      <div>
        <img src="@/assets/icons/c.png" alt="" srcset="" >
        <input v-model="data.datePres" placeholder="Удобная дата получения подарка" :min="minD" max="2023-12-29" type="date"  @blur="testDay" >
      </div>
    </div>  

    <label>Дата рождения:</label> 
    <div :class="{input:true, err:err.dateDay}">
      <div>
        <img src="@/assets/icons/c.png" alt="" srcset="" >
        <input v-model="data.dateDay" type="date" placeholder="Дата рождения" @click="input[1] = 'date'">
      </div>
    </div>  

    <label>Номер телефона:</label> 
    <div :class="{input:true, err:err.phone}">
      <div>
        <img src="@/assets/icons/p.png" alt="" srcset="">
        <div style="padding-right: 4px;" v-if="phone">+7 </div>
        <input type="text" placeholder="Номер телефона" v-model="phone" @keypress="replaser()" @input="event => phone = event.target.value">
      </div>
    </div>  

    <label>Адрес получения подарка:</label> 
    <div :class="{input:true, err:err.locate}">
      <div>
        <img src="@/assets/icons/l.png" alt="" srcset="">
        <textarea type="text" placeholder="Адрес получения подарка" v-model="locate"  list="character" v-if="input[2]"> </textarea>
      </div>  
    </div> 
    <div class="area" v-if="inputSet.length > 0">
        <span v-for="(el, index) in inputSet" :key="index" :value="el.value" @click="locate = el.value, inputSet = []">{{ el.value }}</span>
      </div>
    <button @click="sendInfo">Отправить</button>
  </div>


</template>
<script>
import axios from 'axios'
export default{


    data() {
        return {
            error: false,
            int:5,
            popap:false,
            input:['text', 'text', true],
            locate: '',
            inputSet: [],
            phone: '',
            minD:'',
            err:{
                lastName: false,
                ferstName: false,
                locate: false,
                datePres: false,
                dateDay: false,
                phone: false
            },
            data: {
                lastName: '',
                ferstName: '',
                locate: '',
                datePres: '',
                dateDay: '',
                phone: ''
            },
            textPicker: 'Дата рождения',
            showPicer:false
        };
    },
    methods: {
        testDay(){
          let date = new Date(this.data.datePres)
          let dateNow = new Date()
          if(date.getUTCDay() == 0 || date.getUTCDay() == 6){
            alert("Мы не осуществляем доставку в выходные дни. Пожалуйста выберете другую дату доставки подарка")
            this.data.datePres = ''
          } else if (date <= dateNow){
            alert("Мы можем доставить вам подарок, начиная с завтрашнего дня и исключая выходные и праздники. Пожалуйста, выберете другую дату доставки подарка")
            this.data.datePres = ''
          }
        },

        showPiker(name){
          this.textPicker=name
          this.showPicer=true

        },

        hidePicker(){
          this.showPicer=false
        },

        minDate() {
            let date = new Date();
            let yy = date.getFullYear();
            let mm = date.getMonth();
            let dd = date.getDate();
            dd++
            mm++;
            if (dd < 10)
                dd = "0" + dd;

            this.minD =  yy + '-' + mm + '-' + (dd);
            console.log(this.minD)
        },
        getLocal() {
            console.log("test");
            var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
            var token = "30ae41ad7b466d6f19f6e1e0c5af80e6538b0703";
            var query = this.locate;
            let res = '';
            var options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({ query: query })
            };
            fetch(url, options)
                .then(response => response.text())
                .then(result => {
                res = JSON.parse(result);
                this.inputSet = res.suggestions;
            })
                .catch(error => console.log("error", error));
        },
        replaser() {
            console.log(this.phone);
            let x = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.phone = '' + x[1] + ' ' + x[2] + (x[3] ? '-' + x[3] : '');
        },

        verifFilds(){
          let res =  true
          Object.keys(this.err).forEach((key) => {
            this.err[key] = false

            if(key == "phone" && this.data[key].length != 12){
              this.err[key] = true
              res =  false
            }
            else if((key == "lastName" || key == "ferstName" || key == "locate" || key == "datePres" || key == "dateDay") && this.data[key].length < 2){
              this.err[key] = true
              res =  false
            }
          })
          return res
        },

        sendInfo(){
          console.log(this.data)
          this.data.locate = this.locate
          this.data.phone = this.phone
          if(!this.verifFilds())
            return 0
          axios.post('https://www.testback.swts.online:8001/api/Info', this.data).then(()=>{
            console.log("send")
            this.popap = true
            setInterval(() => {
              this.int--
              if(this.int == 0)
                window.location.href = 'https://adspector.ru';
            }, 1000)
          })
        }
    },

    created(){
      this.minDate()
    },

    watch: {
        locate: {
            handler() {
                if (this.locate.length > 2)
                    this.getLocal();
            },
            deep: true
        },
    },
}
</script>
<style lang="scss" >

@font-face{
  font-family: "Monserat";
  src: local("Monserat"),url("@/assets/fonts/Montserrat-VariableFont_wght.ttf"),
}

*{
  font-family: "Monserat"
}

img.bgImg{
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;


}



label{
  padding: 10px 10px 10px 0;
  width: calc(60% + 10px) ;
  min-width: 300px;
  color: #5E5E5E;
}

div.wrapP{
  background-color: #6060606d;
  position: absolute;

}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

div.area{
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 60%;
  min-width: 300px;
  max-height: 200px;
  overflow-y: auto;
  
  // position: ;

  span{
    // width: 0;
    padding: 5px;
  }
}

div.wr {

  max-width: 600px;
  min-width: 300px;
  // transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

img{
    margin: 0 0 50px 0;

  }
 span{

    margin: 0 0 70px 0;
    color: rgb(27, 27, 27);
    display: block;
    text-align: justify;
    width: 80%;


  }

  button {
    padding: 10px;
    border: none; 
    background-color: #5930eb;
    border-radius: 10px;
    width: 60%;
    height: 60px;
    margin: 25px 0 25px 0;
    color: #fff;
    font-size: 16px;
  }

  .input {
            padding: 10px;
            border: solid 0.5px #5E5E5E;
            background-color: #F6F6F6;
            border-radius: 10px;
            width: 60%;
            margin: 0 0 25px 0;
            min-width: 300px;


            &.err{
                border-bottom: solid 2px #E11900;

            }

            div{
                display: flex;
                align-items: center;
            }

            span{
                font-size: 12px;
                display: block;
                padding-left: 32px;

                .normal{
                    color: #606060;
                }

                &.err{
                    color: #E11900;
                }
            }

            img{
                padding: 10px;
                width: 20px;
                margin: 0;
                .glass{
                    opacity: 10%;
                }
            }

            input, textarea{
                border: none;
              background-color: #F6F6F6;

                width: 100%;
                font-size: 16px;
            }
        }


</style>
